.my_team_container{
    background: white;
    color: black;
    min-height: 100vh;
    padding: 30px 60px;
}

.my_team_heading{
    font-size: 24px;
}

.my_team_table{
    background: white     ;
}

.add_team_conatiner{
    padding: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(34, 51, 84, 0.1);
    margin-bottom: 10px;
}

.table_header{
    background: #F6F8FB;
}

.table_header_data{
    color: #223354;
    opacity: 0.7;
}

.table_row_color{
    color: black;
}

.table_rows .table_row_data{
    padding: 10px 16px;
}

.table_row_name{
    border-radius: 50%;
    background: #1A75FF;
    color: white;
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.last_admitted_text{
    font-size: 12px;
    color: rgba(34, 51, 84, 0.5);
}

.active_user_button, .pending_user_button{
    color: white;
    font-size: 14px;
    font-weight: 400;
    padding: 1px 10px 4px 10px;
    border-radius: 3px;
}

.add_team_member_button, .add_team_member_button:hover{
    border: 1px solid #1A75FF;
    color: #1A75FF;
    background: transparent;
}

.add_team_member_button .MuiSvgIcon-root{
    font-size: 20px;
}

.active_user_button{
    background: #05C22F;
}

.pending_user_button{
    background: #1A75FF;
}

.table_more_icon .MuiSvgIcon-root{
    fill: #0061F4;
    font-size: 24px;
}

.table_more_option_container .MuiPaper-root.MuiPaper-elevation{
    padding: 0 10px;
    background: white;
    color: black;
}

.table_more_option{
    padding: 10px 0;
    border-radius: 0;
}

.resend_invitation{
    border-bottom: 1px solid rgba(34, 51, 84, 0.1); 
}

.table_more_option:hover{
    background: white;
}

.table_rows{
    border-bottom: 1.1px solid rgba(34, 51, 84, 0.1);
}

.table_rows:last-child{
    border-bottom: none;
}

.invite_member_dialog .MuiPaper-root.MuiPaper-elevation{
    background: white;
    width: 600px;
}

.invite_member_dialog_heading{
    color: black;
    font-size: 16px;
}

.invite_member_dialog_title{
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(34, 51, 84, 0.12);
}

.invite_member_dialog_subheading{
    color: #223354;
    font-size: 16px;
    font-weight: 400;
}

.invite_member_dialog .MuiOutlinedInput-root.MuiInputBase-root{
    border: 1px solid rgba(34, 51, 84, 0.12);
}

.invite_member_dialog .Mui-focused:hover .MuiOutlinedInput-notchedOutline,
.invite_member_dialog .Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: transparent;
}

.invite_member_dialog_label{
    margin-bottom: 10px;
    font-weight: 500;
}

.invite_member_dialog .MuiOutlinedInput-input.MuiInputBase-input{
    color: black;
    padding: 12px;
}

.invite_cancel_button, .invite_cancel_button:hover{
    color: white;
    background: rgb(111, 117, 159);
    padding: 5px 25px;
}

.delete_member_dialog .MuiPaper-root.MuiPaper-elevation {
    background: white;
}

.delete_member_dialog_action{
    padding: 16px 24px;
}

.delete_member_button, .delete_member_button:hover{
    background: #FFCBCA;
    padding: 5px 25px;
}

.cancel_delete_member_button{
    padding: 0;
}

.cancel_delete_member_button:hover{
    background: transparent;
}

.cancel_delete_member_button .MuiSvgIcon-root{
    background: #E8F1FF;
    border-radius: 3px;
    color: #1A75FF;
    font-size: 20px;
}

.delete_member_content{
    color: black;
    width: 80%;
    margin: auto;
}