.tablecell_room {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.tablecell_room_data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.table_cell_StartnEnd {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-weight: 400;
}

.tablecell_minutes {
  font-family: 'Roboto';
  font-weight: 400;
}

.tablecell_causeCode {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-weight: 400;
  text-transform: uppercase;
}

.room_icon_chip {
  background: #ecfbe6;
  border-radius: 50%;
}

.cell_height {
  height: 85px;
  /* border: 2px solid red; */
}

.table_header {
  font-weight: bold;
  /* color: #223354; */
  color: black;
}
.header_room {
  padding-left: 10px;
}

.webhook_header_wrapper {
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
}
.webhook_header_wrapper_status {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* sort icons */
.wenhook_table_appId_icon {
  opacity: 0;
}
.wenhook_table_appId:hover > .wenhook_table_appId_icon {
  opacity: 1;
}
.wenhook_table_hookName_icon {
  opacity: 0;
}
.wenhook_table_hookName:hover > .wenhook_table_hookName_icon {
  opacity: 1;
}
.wenhook_table_datetime_icon {
  opacity: 0;
}
.wenhook_table_datetime:hover > .wenhook_table_datetime_icon {
  opacity: 1;
}
.wenhook_table_status_icon {
  opacity: 0;
}
.wenhook_table_status:hover > .wenhook_table_status_icon {
  opacity: 1;
}
