.features_container{
    padding: 40px;
    background: white;
    color: black;
}

.pricing_title{
    font-family: Poppins;
    font-size: 30px;
    text-align: center;
}

.pricing_subtitle{
    font-family: Poppins;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    color: #878282;
}

/* Select Plan */
.manage_plans_tile_title{
    font-size: 24px;
}

.manage_subscription_image{
    height: 120px;
}

.manage_subscription_button_container{
    justify-content: flex-end;
}

.manage_subscription_button{
    background: #1A75FF;
    padding: 10px 20px;
    color: white;
    border-radius: 6px;
    font-weight: 700;
    font-size: 15px;
    border: none;
}

.manage_subscription_button:hover{
    background: #1A75FF;
}

/* Manage Subscription Card */

.feature_plan_card_container.MuiGrid-root.MuiGrid-item{
    padding-left: 10px;
    padding-right: 10px;
}

.plan_card .users_count{
    font-size: 12px;
    color: #373737;
}

.select_plan_button:hover{
    background: #1A75FF;
}

.plan_add_ons{
    color: black;
    font-size: 14px;
    text-align: center;
    line-height: 1.5;
}

.feature_plan_add_ons{
    font-size: 14px;
    text-align: center;
    color: black;

}

/* Add ons */

.addons_container{
    padding: 15px;
    max-width: 700px;
    background: rgba(246, 248, 251, 1);
    margin: auto;
}

.addons_title, .addons_subtitle{
    
    color: black;
    text-align: center;
}

.addons_title{
    font-size: 24px;
    font-weight: 450;
}

.addons_subtitle{
    font-size: 18px;
    font-weight: 300;
}

.addons_info_container{
    margin: 0;
    width: 100%;
}

.addons_container .addons_info{
    padding: 0 10px;
}

.addons_container .addons_info>div{
    border-top: 1px solid black;
}

.addons_container .addons_info:nth-of-type(1)>div,
.addons_container .addons_info:nth-of-type(2)>div{
    border-top: none;
}

.addons_info .MuiTypography-root{
    font-size: 16px;
    color: black;
    font-weight: 400;
    padding: 15px 0;
}

.addons_additional_details1{
    color: black;
    text-align: center;
    width: 90%;
    margin: auto;
}

.addons_additional_details2{
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    line-height: 1.5;
}

/* Portego Features */

.features_table{
    width: 70%;
    margin: auto;
    background: white;
    color: black;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 3px rgba(0,0,0,0.24);
    border-radius: 6px;
}

.features_heading, .features_record{
    padding: 15px 30px;
    font-weight: 700;
}

.features_heading{
    font-size: 20px;
    color: #223354;
}

.features_record{
    font-size: 16px;
    border-bottom: 1px solid #eee;
}

.feature_table_row:last-child .features_record{
    border-bottom: none;
}

@media screen and (max-width: 600px) {

    .features_container{
        padding: 0;
    }

    .addons_info .MuiTypography-root{
        font-size: 14px;
    }

    .features_table {
        width: 100%;
        max-width: 90vw;
        overflow: scroll;
        margin-top: 15px;
        margin-bottom: 75px;
    }

    .features_record {
        font-size: 16px;
    }

    .feature_title {
        font-size: 25px;
        margin: 20px 5px 10px;
    }

    .feature_subtitle {
        font-size: 20px;
        margin: 5px 30px;
    }

    .plan_card .plan_amount_container{
        margin-bottom: 10px;
    }

    .addons_container {
        margin: 20px;
    }

    .addons_additional_details2{
        margin-bottom: 15px;
    }
}

