.tablecell_room {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.tablecell_room_data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.table_cell_StartnEnd {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-weight: 400;
}

.tablecell_minutes {
  font-family: 'Roboto';
  font-weight: 400;
}

.tablecell_causeCode {
  display: flex;
  flex-direction: column;
  font-family: 'Roboto';
  font-weight: 400;
  text-transform: uppercase;
}

.room_icon_chip {
  background: #ecfbe6;
  border-radius: 50%;
}

.cell_height {
  height: 85px;
  /* border: 2px solid red; */
}

.table_header {
  font-weight: bold;
  /* color: #223354; */
  color: black;
}
.header_room {
  padding-left: 10px;
}
