@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.align_center {
  display: flex;
  align-items: center;
}

.align_right {
  display: flex;
  align-items: flex-end;
}

.align_start {
  display: flex;
  align-items: flex-start;
}

.justify_center {
  display: flex;
  justify-content: center;
}

.justify_space_between {
  display: flex;
  justify-content: space-between;
}

.justify_end {
  display: flex;
  justify-content: flex-end;
}

.column_center {
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.font_bold {
  font-weight: 700;
}

.snackbar_success {
  width: 100%;
  background: #c5f2c7;
  color: #1f8b24;
}

.snackbar_failure_deletion {
  width: 100%;
  color: #ff1a43;
  background: #ffe8ec;
}
