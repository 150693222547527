.footer {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
  padding: 30px;
}
.footer-mobile{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  list-style: none;
}

.nav__head {
  font-weight: 400;
  font-family: Poppins;
  font-size: 26px;
  color: #ffffff;
}

.nav__title {
  font-weight: 600;
  font-family: Poppins;
  font-size: 18px;
  line-height: 18px;
  color: #000000;
  padding: 4px;
}

.nav__title_mobile {
  font-weight: 600;
  font-family: Poppins;
  font-size: 14px;
  line-height: 16px;
  color:  #000000;
  padding: 4px;
}

/* .nav__item {
    margin: 0 1rem;
    padding: 0 1rem;
} */

.sub-list {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #338186;
  ;
}
.sub-list_mobile{
  font-family: Poppins;
  font-size: 10px;
  line-height: 13px;
  font-weight: 400;
  color: #338186;
}
.nav__ul {
  padding: 0 10px 0 0;
}

.footer li {
  line-height: 2em;
  list-style: none;
}
.footer-mobile li {
  line-height: 2em;
  list-style: none;
}

.footer a {
  text-decoration: none;
  cursor: pointer;
  padding:4px;
}
.footer-mobile a {
  text-decoration: none;
  cursor: pointer;
  padding:4px;
}
.footer-privacy-condition a{
  color: #FFFFFF;
  text-decoration: none;
  cursor: pointer;
}
.footer-item-left-box{
  padding-left: 15px !important;
}

.footer-item-right-box{
  padding-left: 5px !important;
  padding-right: 15px !important;
  
}

/* a:hover {
    border-bottom: 1px solid #FFFFFF; 
} */

.footer a:hover,
.footer-mobile a:hover,
.footer-privacy-condition a:hover {
  color:#808080;
  font-weight: 400;
}

.footer .active,
.footer-mobile .active,
.footer-privacy-condition .active {
  border-bottom: 1px solid #3087EC;
    color: #ffffff;
    font-weight: 400;
    background-color: #24263A;
}

.footer .after,
.footer-mobile .after,
.footer-privacy-condition a .after{
  background-color: #24263a;
}

.footer__nav {
  display: flex;
  flex-flow: row wrap;
  margin: 0% 10%;
}

.footer__nav > * {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul--extra {
  column-count: 1;
  column-gap: 5em;
}

@media (min-width: 750px) {
  .footer__nav > * {
    flex: 1;
  }
}

@media only screen and (min-width: 2560px) {
  .footer {
    justify-content: center;
  }
}
/* @media (max-width: 500px) {
  .footer {
    justify-content: space-between;
    padding-right: 0rem;
    margin-right: 0rem;
  }
} */
