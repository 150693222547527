.plans_container{
    padding: 40px;
}

.sub_title{
    font-size: 17px;
}

/* Common Classes for Plans Card */

.plans_tile{
    background: white;
    color: black;
    padding: 20px;
    height: 100%;
    box-shadow: 0px 2px 2px 0px rgba(159, 162, 191, 0.32);
}

/* Active Subscription */

.active_plans_tile_title{
    font-size: 14px;
}

.message_icon_container{
    width: 60px;
    height: 60px;
    background: #44D600;
    border-radius: 6px;
    box-shadow: 0px 3px 12px 2px rgba(68, 214, 0, 0.35);
}

.text_dev{
    font-size: 37px;
}

.view_subscription_plan .view_subscription_plan_link{
    font-size: 14px;
    color: #1A75FF;
}

.view_subscription_plan .view_subscription_plan_text{
    font-size: 14px;
    color: #888;
}

/* Manage Subscription */

.manage_plans_tile_title{
    font-size: 24px;
}

.manage_subscription_image{
    height: 120px;
}

.manage_subscription_button_container{
    justify-content: flex-end;
}

.manage_subscription_button{
    background: #1A75FF;
    padding: 10px 20px;
    color: white;
    border-radius: 6px;
    font-weight: 700;
    font-size: 15px;
    border: none;
}

.manage_subscription_button:hover{
    background: #1A75FF;
}

/* Manage Subscription Card */

.manage_subscription_card{
    background: white;
    color: black;
    box-shadow: 0px 2px 2px 0px rgba(159, 162, 191, 0.32);
    padding: 20px 40px;
    height: 100%;
}

.subscription_type, .plan_amount{
    font-size: 24px;
}

.active_users, .current_plan{
    color: #888;
    font-size: 17px;
}

.users_count{
    font-size: 64px;
    color: #223354;
}

.plan_amount_container{
    background: rgba(34, 51, 84, 0.1);
    padding: 20px 5px;
    width: 100%;
}
.plan_amount{
    text-align: center;
}

.upgrade_plan_button{
    background: #1A75FF;
    border: none;
    border-radius: 6px;
    color: white;
    padding: 10px 50px;
    font-size: 13px;
    font-weight: 700;
}

.upgrade_plan_button:hover{
    background: #1A75FF;
}

.subscription_add_ons{
    color: black;
    font-size: 20px;
    text-align: center;
}

/* Upgrade plans */

.upgrade_plans_tile_title{
    font-size: 25px;
}

.upgrade_plans_tile_subtitle{
    font-size: 16px;
    color: #888;
}

.upgrade_subscription_image{
    height: 180px;
}

.arrow_icon_container{
    padding: 15px;
    width: fit-content;
    border-radius: 50%;
    background: #F6F8FB;

}

.manage_user_title{
    color: black;
    font-size: 18px;
}

.get_started_button{
    color: #1A75FF;
    border: 1px solid #1A75FF;
    font-size: 15px;
    font-weight: 700;
    padding: 10px 20px;
    background: white;
    border-radius: 6px;
}

